import { Link } from 'react-router-dom';
import Wrapper from 'common/Wrapper';
import Curve from 'common/Curve'
import 'styles/Home.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faLongArrowRight, faShieldAlt, faSearch, faUserSecret } from "@fortawesome/pro-light-svg-icons";

const Home = ()=> {
  return (
    <Wrapper active="Index" lang="en">
      <Index />
      <Curve />
      <Teaser />
      <Gartner />
      <Gartner2 />
      <TopTech />
      <Teasers />
      <Product />
    </Wrapper>
  )
}

const Index = ()=> {
  return (
    <section className="index-intro2">
      <div className="container">
        <div className="slogan">
            <h2>
              <div className="origin">
                <img src="/images/index/hunt_or_get_hunted.png"
                  alt="Hunt or get hunted" />
              </div>
              <div className="top">
                <img src="/images/index/hunt_or_get_hunted.png"
                  alt="Hunt or get hunted" />
              </div>
              <div className="bottom">
                <img src="/images/index/hunt_or_get_hunted.png"
                  alt="Hunt or get hunted" />
              </div>
            </h2>
            <h4>
              We're going to learn about <strong>Hunting, Investigation, and Cyber Threats</strong>
              <br/>
              Provides both <strong>detection and response technologies.</strong>
              <p>
                <Link to="/en/network_blackbox.html">
                  <FontAwesomeIcon icon={faLongArrowRight} />
                  &nbsp;
                  Learn about Cyber Threat Hunting Solutions
                </Link>
              </p>
            </h4>
          </div>
        </div>
      <div className="network">
       <canvas id="particles-intro" width="1904" height="647" style={{width: "100%", height: "100%"}} ></canvas>
      </div>
    </section>
  )
}

const Teaser = () => {
  return (
    <section className="index-teaser">
      <div className="container">
        <h2>It establishes a more secure and reliable
          <span className="break-line-auto" />network environment than ever before.
        </h2>
        <h3>Quad Miners creates&nbsp;
          <strong>the Network Blackbox</strong>,
          <span className="break-line-auto" />
          a solution that proactively detects and responds to cyber threats.
        </h3>
        <div className="video-container">
          <iframe title="youtube" width="853" height="480" src="https://www.youtube.com/embed/p42rUq0X-PI?cc_load_policy=1" frameBorder="0" allowFullScreen={true}></iframe>
        </div>
      </div>
    </section>
  )
};

const Gartner = () => {
  return (
    <section className="index-gartner">
      <div className="container">
      <h4>2020 - 2024 RELEASE "Quad Miners" named <em>Network Detection and Response</em> vendor in Gartner.</h4>
      </div>
    </section>
  )
};

const Gartner2 = () => {
  return (
    <section className="index-gartner2">
      <div className="container">
        <h4>
          Compared
          with traditional approaches, where malicious behavior is defined ahead of time and detection engines inspect traffic looking for matches, NDR takes a reverse approach. Instead of inspecting traffic against a list of known bad payloads or behaviors, NDR focuses on the anomalies and calculates a probability as to whether that anomaly is malicious.
        </h4>
        <p>
          Gartner, Emerging Technologies: Adoption Growth Insights for Network Detection and Response.
        </p>
      </div>
    </section>
  )
};

const TopTech = () => {
  return (
    <div className="index-featured">
      <section className="index-top-tech">
        <div className="container">
          <a rel="noreferrer noopener" href="https://www.apacbusinessheadlines.com/Toptech-web-version/Quad-Miners/" target="_blank">
            <img src="/images/network_blackbox/2020_top_tech_bb.jpg"
              alt="APAC Business Headlines - South korea's Top tech company of 2020" />
          </a>
        </div>
      </section>
      <section className="index-product" style={{
          verticalAlign: 'middle'
        }}>
        <div className="container" style={{
          padding: '20px 40px'
        }}>
          <p>
          Cloud Blackbox is the most advanced physical-virtualized network security solution in the market.
          </p>
          <cite>APAC Business Headlines</cite>
        </div>
      </section>
    </div>
  )
};


const Teasers = () => {
  return (
    <section className="index-teasers">
      <div className="container">
        {[
          'BDN7P1jvTSE',
          'Pv44VFi6shM',
          'nTMJglDW328',
          'U7jwZ79Tn6w'
        ].map(d=>(<div key={d}>
          <a rel="noreferrer noopener" href={`https://www.youtube.com/watch?v=${d}`} target="_blank">
            <img src={`https://img.youtube.com/vi/${d}/maxresdefault.jpg`} alt="" />
          </a>
        </div>))}
      </div>
    </section>
  )
};

const Product = () => {
  return (
    <div className="index-featured">
      <section className="index-product2">
        <div className="container">
          <p>A solution that will store and reproduce full packets.
            <span className="break-line-auto"></span>
            Detect and eliminate all cyber threats.
          </p>
          <ul>
            <li>
              <FontAwesomeIcon icon={faKey} />
              &nbsp; Intelligent Security
            </li>
            <li>
              <FontAwesomeIcon icon={faShieldAlt} />
              &nbsp; Data Loss Prevention
            </li>
            <li>
              <FontAwesomeIcon icon={faSearch} />
              &nbsp; Smart NIDS
            </li>
            <li>
              <FontAwesomeIcon icon={faUserSecret} />
              &nbsp; Network Forensics
            </li>
          </ul>
        </div>
      </section>
      <section className="index-award">
        <div className="container">
          <a rel="noreferrer noopener" href="https://cyber-security.apacciooutlook.com/vendor/quad-miners-the-rising-rookie-in-network-security-cid-4326-mid-262.html" target="_blank">
            <img src="/images/network_blackbox/2019_cyber_security_top_10.png" alt="APAC CIO Outlook Top 10 - Cyber Security Solution Providers - 2019" />
          </a>
        </div>
      </section>
    </div>
  )
};

export default Home; 
