import Wrapper from "common/Wrapper";
import "../styles/Home2.scss";
import logo1 from "../images/network_blackbox/bb2_text_white.png";
import logo2 from "../images/cloud_blackbox/cb_center_white.png";
import logo3 from "../images/quadx/quadx_logo_white.png";
import logo4 from "../images/index/gartner.png";
import logo5 from "../images/index/forbes.png";
import logo6 from "../images/index/apac.png";

const Home = () => {
  return (
    <Wrapper active="Index" lang="ko">
      <div className="Home2">
        <section className="sec1">
          <h2 className="title">
            <div>
              Business Resilience
            </div>
            <div>
              <b className="muted">with</b> Intuitive Insights
            </div>
            <div>
              <b className="muted">on the</b> digital world.
            </div>
          </h2>
          <div className="layer">
            <div className="ani">
              <div className="monitor">
                <div className="camera o-x">
                  <div className="camera o-y">
                    <div className="camera o-z">
                      <div className="vr">
                        {Array(20).fill().map((a,i)=>(
                          <div className="vr_layer" key={i}>
                            <div className="vr_layer_item" />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="desc">
              <div>
                <span className="muted">Quad Miners는 사이버 위협에서의</span>
              </div>
              <div>
                Hunting, Investigation, Detection & Response{" "}
                <span className="muted">기술을 제공합니다.</span>
              </div>
            </div>
          </div>
        </section>
        <section className="sec2">
          <div className="sec-title">
            Quad Miners Position
          </div>

          <div className="position">
            <div className="wrapper">
              <div>
                <div className="desc">
                  Gartner 5년 연속 등재
                </div>
                <div className="text">
                  <div>NDR</div>
                </div>
              </div>
              <div>
                <div className="desc">
                  설명 가능한 AI 엔진
                </div>
                <div className="text">
                  <div>Threat</div>
                  <div>Hunting</div>
                </div>
              </div>
              <div>
                <div className="desc">
                  첨단 무기체계 시스템
                </div>
                <div className="text">
                  <div>Embedded</div>
                  <div>Security</div>
                </div>
              </div>
              <div>
                <div className="desc">
                  엔드포인트를 넘어 클라우드까지
                </div>
                <div className="text">
                  <div>XDR</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sec3">
          <div className="bg">
            <svg className="bokeh" viewBox="0 0 10 10"
              xmlns="http://www.w3.org/2000/svg">
              <circle cx="10%" cy="85%" r="75%"/>
              <circle cx="45%" cy="50%" r="15%"/>
              <circle cx="85%" cy="35%" r="30%"/>
              <circle cx="60%" cy="85%" r="20%"/>
              <circle cx="45%" cy="50%" r="30%"/>
              <circle cx="35%" cy="25%" r="20%"/>
              <circle cx="90%" cy="-25%" r="35%"/>
              <circle cx="-15%" cy="30%" r="30%"/>
              <circle cx="65%" cy="85%" r="55%"/>
              <circle cx="45%" cy="50%" r="20%"/>
            </svg>
          </div>
          <div className="text" style={{ top: '20%' }}>
            <div className="text1 muted">
              <div>디지털화된 세상의 모든 움직임을 분석하고</div>
              <div>
                <span style={{color: "#fff"}}>직관성있는 인사이트</span>를 제공합니다.
              </div>
            </div>
          </div>
          <div className="text" style={{ bottom: '10%' }}>
            <div className="text2 muted">
              <div>
                <span style={{color: "#fff"}}>고성능 풀패킷 캡처</span>{" "}
                데이터베이스로 네트워크 상의 트래픽을 안정적으로 수집하고,
              </div>
              <div>
                수집한 빅데이터를{" "}
                <span style={{color: "#fff"}}>인공지능 분석엔진</span>을 통해
                상황을 식별하고 가시화합니다.
              </div>
            </div>
          </div>
        </section>

        <section className="sec4">
          <div className="sec-title">OUR PRODUCT</div>
          <div className="logo">
            <div>
              <div className="networkblackbox">
                <img src={logo1} alt="Network Blackbox logo" />
              </div>
              <div className="cloudblackbox">
                <img src={logo2} alt="Cloud Blackbox logo" />
              </div>
              <div className="quadx">
                <img src={logo3} alt="QUAD X logo" />
              </div>
            </div>
          </div>

          <div className="desc">
            {"Protecting Your Digital Frontier".split('').map((a, i)=>(
              <span style={{ animationDelay: `${i*0.1}s` }} key={i}>
                {a}
              </span>
            ))}
          </div>
        </section>

        <section className="sec5 container">
          <div className="logo">
            <div className="gartner">
              <div className="sec-title title">Trusted by</div>
              <img src={logo4} alt="gartner" />
            </div>
            <div className="forbes">
              <img src={logo5} alt="forbes" />
            </div>
            <div className="apac">
              <img src={logo6} alt="apac" />
              <div>and more...</div>
            </div>
          </div>
        </section>

        <section className="sec6 container">
          <div className="title">
            <div>Reinventing Security,</div>
            <div>Embracing Zero-Trust.</div>
          </div>
          <div className="desc muted">
            <div>
              Quad Miners가 제공하는 사이버 디펜스 아키텍처는 <span style={{ color: "#fff" }}>Zero-Trust Framework</span>를 수용할 수 있도록 지원하며 <span style={{ color: "#fff" }}>
                모든 수준에서 종합적인 보안을 보장
              </span>합니다. 신뢰 가정을 제거하고, 엄격한 접근 통제를 구현함으로써 디지털 시대에 맞는 보안 패러다임을 재정립합니다.
            </div>
          </div>
        </section>

        <section className="sec7">
          <div className="wrapper">
            {[
              'hLxLEcJNOqQ',
              'JazatoD5DQ8',
              'BDN7P1jvTSE',
              'Pv44VFi6shM',
              'nTMJglDW328',
              'U7jwZ79Tn6w'
            ].map(d=>(<div key={d}>
              <a rel="noreferrer noopener" href={`https://www.youtube.com/watch?v=${d}`} target="_blank">
                <img src={`https://img.youtube.com/vi/${d}/maxresdefault.jpg`} alt="" />
              </a>
            </div>))}
          </div>
        </section>

      </div>
    </Wrapper>
  );
};

export default Home;
